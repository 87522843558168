import { AppServiceEnum } from '@/appConfig'

export interface AppPageServiceConfig {
  serviceAppId: AppServiceEnum
  appUrl: string
  title: string
  description: string
  standoutFeatures: StandoutFeature[]
  forceDisable?: boolean
  featured?: boolean
}

export type StandoutFeature =
  | StandoutFeatureForTimeSpent
  | StandoutFeatureForEarningInUsd
  | StandoutFeatureForMonthlyEarningInUsd
  | StandoutFeatureInfinitePossibleValues

export enum StandoutFeatureType {
  timeSpent = 'timeSpent',
  earningInUsd = 'earningInUsd',
  monthlyEarningInUsd = 'monthlyEarningInUsd',
  infinitePossibleValues = 'infinitePossibleValues',
}
export interface StandoutFeatureForTimeSpent {
  type: StandoutFeatureType.timeSpent
  timeSpentInMinutes: number
}
export interface StandoutFeatureForEarningInUsd {
  type: StandoutFeatureType.earningInUsd
  amount: number
}
export interface StandoutFeatureForMonthlyEarningInUsd {
  type: StandoutFeatureType.monthlyEarningInUsd
  amount: number
}

export interface StandoutFeatureInfinitePossibleValues {
  type: StandoutFeatureType.infinitePossibleValues
}
