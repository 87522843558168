import Page from '@/components/Page'
import { styled as muiStyled, useTheme } from '@mui/material/styles'
import { Box, Grid, Container, Typography, BoxProps } from '@mui/material'
import { MotionViewport, varFade } from '@/components/animate'
import find from 'lodash/find'
import { m } from 'framer-motion'
import Layout from '@/layouts/index'
import { AppServiceCard } from '@/sections/apps'
import { appPages } from '@/sections/apps/configs'
import type { GetStaticPaths, GetStaticProps } from 'next'
import { GlobalSetting, settingService } from '@/services/page'
import { ParsedUrlQuery } from 'querystring'
import { APPS, AppIdEnum, appsConfigs } from '@/appConfig'
import { AppPageServiceConfig } from '@/sections/apps/types'
import pathService from '@/services/path'

const RootStyle = muiStyled('div')(({ theme }) => ({
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11),
    paddingBottom: theme.spacing(5),
  },
}))

interface AppPageProps {
  appPageServiceConfigs?: AppPageServiceConfig[] | []
  globalSetting?: GlobalSetting
  preview?: boolean
}

interface PathProps extends ParsedUrlQuery {
  appId: string
}

AppPage.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout variant="main">{page}</Layout>
}

export default function AppPage(props: AppPageProps) {
  const theme = useTheme()
  const { appPageServiceConfigs = [] } = props

  const featuredAppPageServiceConfigs = appPageServiceConfigs.filter(
    (appPageServiceConfig) => appPageServiceConfig.featured
  )
  const nonFeaturedAppPageServiceConfigs = appPageServiceConfigs.filter(
    (appPageServiceConfig) => !appPageServiceConfig.featured
  )

  const sharedBoxProps: BoxProps = {
    sx: {
      textAlign: 'center',
      mb: 5,
      mt: 10,
    },
  }

  return (
    <Page title="AI Tools - Store">
      <RootStyle>
        {featuredAppPageServiceConfigs.length !== 0 && (
          <Container component={MotionViewport}>
            <Box {...sharedBoxProps}>
              <m.div variants={varFade().inUp}>
                <Typography variant="h2" sx={{ mb: 2, color: theme.palette.primary.main }}>
                  Featured Services
                </Typography>
              </m.div>
            </Box>
            <Grid container spacing={5}>
              {featuredAppPageServiceConfigs.map((appPage) => (
                <Grid key={appPage.appUrl} item xs={12} md={12}>
                  <AppServiceCard appPageServiceConfig={appPage} />
                </Grid>
              ))}
            </Grid>
          </Container>
        )}

        <Container component={MotionViewport}>
          <Box {...sharedBoxProps}>
            <m.div variants={varFade().inUp}>
              <Typography variant="h3" sx={{ mb: 2, color: theme.palette.primary.main }}>
                Services
              </Typography>
            </m.div>
          </Box>
          {nonFeaturedAppPageServiceConfigs.length ? (
            <Grid container spacing={5}>
              {nonFeaturedAppPageServiceConfigs.map((appPage) => (
                <Grid key={appPage.appUrl} item xs={12} md={6}>
                  <AppServiceCard appPageServiceConfig={appPage} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography
              variant="body2"
              sx={{ mb: 2, textAlign: 'center', letterSpacing: '0.25rem' }}
            >
              No enabled service
            </Typography>
          )}
        </Container>
      </RootStyle>
    </Page>
  )
}

export const getStaticPaths: GetStaticPaths<PathProps> = async () => {
  const { getDefaultPaths } = pathService()
  const paths = APPS.flatMap((app) => getDefaultPaths(app.id)) ?? []

  return {
    paths,
    fallback: true,
  }
}

export const getStaticProps: GetStaticProps<AppPageProps, PathProps> = async ({
  preview = false,
  params,
  locale,
}) => {
  if (!params) throw new Error('No path parameters found')
  const { appId } = params
  const { getGlobalSetting } = settingService(appId)
  const globalSetting = await getGlobalSetting({ locale })
  const appConfig = find(appsConfigs, { appIdEnum: appId as AppIdEnum })
  if (!appConfig) throw new Error('No app found')

  const enabledAppPages: AppPageServiceConfig[] | [] =
    appPages.filter((appPage) => {
      if (appConfig?.enableServicesMap) {
        return appConfig?.enableServicesMap[appPage.serviceAppId]
      }
      return false
    }) ?? []

  return {
    props: { preview, globalSetting, appPageServiceConfigs: enabledAppPages },
    revalidate: 3600,
  }
}
