import React from 'react'
import { AppPageServiceConfig, StandoutFeatureType } from './types'
import { PATH_APP_SERVICES } from '@/routes/paths'
import {
  Card,
  Stack,
  Button,
  Typography,
  styled,
  TypographyProps,
  Chip,
  Divider,
  CardProps,
} from '@mui/material'
import Link from 'next/link'
import { shinyCss } from '@/theme/palette'

interface AppServiceCardProps {
  appPageServiceConfig: AppPageServiceConfig
}

const ListContainer = styled('div')`
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
`

const StyledCard = styled(Card)`
  ${shinyCss}
`

export function AppServiceCard(props: AppServiceCardProps) {
  const {
    appPageServiceConfig: { appUrl, title, description, forceDisable, standoutFeatures, featured },
  } = props

  const sharedCardProps: CardProps = {
    sx: {
      p: 5,
      boxShadow: 0,
    },
  }

  const sharedComponent = (
    <Stack spacing={2}>
      <Typography variant="h6" component="h1" color={forceDisable ? 'gray' : 'inherit'}>
        {title}
      </Typography>
      <Typography variant="caption" component="h3" color={forceDisable ? 'gray' : 'inherit'}>
        {description}
      </Typography>
      <div />
      <Divider sx={{ borderStyle: 'dashed' }} />
      <div />
      {standoutFeatures.map((standoutFeature) => {
        let component = <div />
        const sharedTypographyProps: TypographyProps = {
          variant: 'h6',
        }
        switch (standoutFeature.type) {
          case StandoutFeatureType.timeSpent: {
            component = (
              <>
                <Typography {...sharedTypographyProps}>Time Spend</Typography>
                <Typography textAlign="right" {...sharedTypographyProps}>
                  <Chip label={`${standoutFeature.timeSpentInMinutes} Minutes`} size="medium" />
                </Typography>
              </>
            )
            break
          }
          case StandoutFeatureType.earningInUsd: {
            component = (
              <>
                <Typography {...sharedTypographyProps}>Estimate Earning In USD</Typography>
                <Typography textAlign="right" {...sharedTypographyProps}>
                  <Chip label={`${standoutFeature.amount} USD`} size="medium" />
                </Typography>
              </>
            )
            break
          }
          case StandoutFeatureType.monthlyEarningInUsd: {
            component = (
              <>
                <Typography {...sharedTypographyProps}>Estimate Monthly Earning In USD</Typography>
                <Typography textAlign="right" {...sharedTypographyProps}>
                  <Chip label={`${standoutFeature.amount} USD`} size="medium" />
                </Typography>
              </>
            )
            break
          }
          case StandoutFeatureType.infinitePossibleValues: {
            component = (
              <>
                <Typography {...sharedTypographyProps}>Outcome</Typography>
                <Typography textAlign="right" {...sharedTypographyProps}>
                  <Chip label={`Up to your imagination`} size="medium" />
                </Typography>
              </>
            )
            break
          }

          default:
            break
        }

        return <ListContainer key={standoutFeature.type}>{component}</ListContainer>
      })}
      <div />
      <Link href={`${PATH_APP_SERVICES.root}${appUrl}`} passHref prefetch>
        <Button
          disabled={forceDisable ?? false}
          size="large"
          fullWidth
          variant="outlined"
          href={`${PATH_APP_SERVICES.root}${appUrl}`}
          LinkComponent="a"
        >
          {!forceDisable ? 'Launch' : 'Premium Only'}
        </Button>
      </Link>
    </Stack>
  )

  if (featured) {
    return <StyledCard {...sharedCardProps}>{sharedComponent}</StyledCard>
  }

  return <Card {...sharedCardProps}>{sharedComponent}</Card>
}
